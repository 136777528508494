import { ContractIdRequest, ContractModesEnum, ContractTermEnum, ContractTypesEnum, GetTaxesByBpIdDto, IndexedDataDto, PurchaseContractDto, PurchaseContractService, PurchaseDetailsDto, PurchaseTermDataDto, QualityPriceDataDto, SalesContractService, SpecTypesEnum } from '@exportx/shared-models-and-services';
import { Button, Card, Col, Collapse, Divider, Drawer, Empty, Modal, Row, Table, Tabs, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertMessages, isPermissionExist, StatusTag, useAuthState } from '../../../common';
import { CoalIndexView } from '../../masters/components/coal-index-prices';
import { SpecsViewPage } from './specs/specs-view-page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faXmark } from '@fortawesome/free-solid-svg-icons';
import TypicalDataView from './specs/typical-data-view';
import { TypicalModel } from './specs/typical.model';
import SizeDistributionDataView from './specs/size-distribution-data-view';
import ASHFusionView from './specs/ash-fusion';
import UltimateDataView from './specs/ultimate-data-view';
import ASHDataView from './specs/ash-data-view';
import { AshModel } from './specs/ash.model';
import { UltimateModel } from './specs/ultimate.model';
import AnalysisDataView from './specs/analysis-data-view';
import { ArrowRightOutlined } from '@ant-design/icons';
import { title } from 'process';
import { render } from '@testing-library/react';

interface IQualityDetailedView {
    qualityData?: QualityPriceDataDto[];
    pcId?: string;
    classes?: string;
    type: ContractModesEnum;
    plantCode: string;
    snapShotData: PurchaseContractDto[];
    contractType: ContractTypesEnum;
    vat: GetTaxesByBpIdDto[];
    contractStatus?: string;
    viewLaycanHandler?: (pcqId: string) => void;
    mode?: string;
}
class IQualityGrid {
    typeId: string;
    quality: string;
    quantity: string;
    tolerance: number;
    mineName: string;
    specStandard: SpecTypesEnum;
    noPriceAdjustment: boolean;
    layCan: string;
    purchasingTerm: string;
    rowSpan: number;
    anchorageName: string;
    fasAnchorage: string;
    jettyName: string;
    isIndexLinked: boolean;
    fcCharges: number;
    freightPricePMT: number;
    vatAmount: number;
    coalPricePMT: number;
    dischargePort: string;
    purchaseType: string;
    purchaseData: PurchaseDetailsDto;
    exportDocumentation: string;
    businessNumber: string;
    vat: number;
    purchaseTermData: PurchaseTermDataDto;
    qualityAdjustments: string;
}
const { TabPane } = Tabs;
const { Panel } = Collapse;
export const QualityDetailView = (props: IQualityDetailedView) => {
    const { snapShotData, contractType, vat, contractStatus, viewLaycanHandler, mode } = props;
    const { formatMessage: fm } = useIntl();
    const { authContext } = useAuthState();
    const [qualityData, setQualityData] = useState([]);
    const [snapQuality, setSnapQuality] = useState([]);
    const [qualities, setQualities] = useState([]);
    const [enableSpecData, setEnableSpecData] = useState<boolean>(false);
    const [activeQualityData, setActiveQualityData] = useState<IQualityGrid>();
    const [activeSnapQualityData, setActiveSnapQualityData] = useState<IQualityGrid>();
    const [enableQualityAdjustments, setEnableQualityAdjustments] = useState<boolean>(false);
    const [activePurchaseId, setActivePurchaseId] = useState(null);
    const [activeIndexedFormData, setActiveIndexedFormData] = useState<IndexedDataDto>(null);
    const [activeSpecType, setActiveSpecType] = useState(SpecTypesEnum.ASTM);
    const [noVatPlantCodes, setNoVatPlantCodes] = useState<boolean>(false);
    const [pricePermission, setPricePermission] = useState(false);
    const [showClosedLaycans, setShowClosedLaycans] = useState(false);
    const service = props.type === ContractModesEnum.SALES ? new SalesContractService() : new PurchaseContractService();

    useEffect(()=>{
        if(props.type === ContractModesEnum.SALES){
            setPricePermission(isPermissionExist([99]))
        } else if(props.type === ContractModesEnum.PURCHASE){
            setPricePermission(isPermissionExist([114]))
        } else {
            setPricePermission(isPermissionExist([99,114]))
        }
    },[])

    useEffect(() => {
        
        const snap_qualities = JSON.parse(JSON.stringify(snapQuality));
        const addendum_qualites = JSON.parse(JSON.stringify(qualityData));
        const new_qualities = []
        if (contractType === ContractTypesEnum.ADDENDUM) {

        const removed_records = snap_qualities?.filter(({ qualitySeq: id1 }) => !addendum_qualites.some(({ qualitySeq: id2 }) => id2 === id1));
        console.log('removed_records', removed_records)
        for (const obj of removed_records) {
            obj.color = "#f65c8d1f";
            new_qualities.push(obj);
        }
    }

        // const common_records = snap_qualities.filter(item => addendum_qualites.filter(e => JSON.stringify(item.qualitySeq) === JSON.stringify(e.qualitySeq)));
        // console.log('common_records', common_records)
        // for (const el1 of common_records) {
        //     new_qualities.push(el1);
        // }

        // const new_records = addendum_qualites.filter(({ qualitySeq: id1 }) => !snap_qualities.some(({ qualitySeq: id2 }) => id2 === id1));
        // console.log('new_records', new_records)

        // for (const el of new_records) {
        //     el.color = '#41c1971f';
        //     new_qualities.push(el);
        // }
        setQualities([...new_qualities, ...addendum_qualites])
        if(authContext.defaultPlant === 'AVS' || authContext.defaultPlant === 'SRPL'){
            setNoVatPlantCodes(true);
        }

    }, [snapQuality, qualityData])
    const valiDateWithSnapShotData = (qualitySeq: string, key: string, value: string): any => {
        const filterSeq = snapQuality?.find((e) => e.qualitySeq === qualitySeq)
        let valueAtGivenKey: any = filterSeq && filterSeq[key];

        if (valueAtGivenKey) {
            if (key === 'quantity') {
                const roundedValueAtGivenKey = Math.round(Number(valueAtGivenKey));
                const roundedValue = Math.round(Number(value));
                if (roundedValueAtGivenKey === roundedValue) {
                    return value;
                }
            } else {
                if (valueAtGivenKey === value) {
                    return value;
                }
            }
                if (contractType === ContractTypesEnum.ADDENDUM) {
                    return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{key === 'quantity' ? Math.round(Number(valueAtGivenKey)) : valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={snapShotData?.[0]?.contractDetails?.contractNo?.replace('SNP', 'ADD')}><b>
                        {value}
                    </b></Tooltip></>
                }
            };
        return value;//'hhhhhhhhhhhh'
    }
    
    const CheckTypesDiffHandler = (qualitySeq, typeSeq, key: string, value: string) => {
        const filterSeq = snapQuality?.find((e) => e.qualitySeq === qualitySeq)?.purchaseTypeData?.find((type) => type.typeSeq === typeSeq)
        let valueAtGivenKey: any = filterSeq && filterSeq[key];
        if (valueAtGivenKey) {
            if (valueAtGivenKey === value) {
                return value;
            } else {
                if (contractType === ContractTypesEnum.ADDENDUM) {
                    return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={snapShotData?.[0]?.contractDetails?.contractNo?.replace('SNP', 'ADD')}><b>
                        {value}
                    </b></Tooltip></>
                }
            };
        }
        return value;//'hhhhhhhhhhhh'

    }


    useEffect(() => {
        if (props?.pcId)
            getQualityDetailsByPcId(props.pcId, props.plantCode);
    }, []);


    const laycanDateFormate = (value: any) => value && Array.isArray(value) ? `${moment(value[0]).format('DD-MMM-YY')} to ${moment(value[1]).format('DD-MMM-YY')}` : `${moment(value?.split(',')[0]).format('DD-MMM-YY')} to ${moment(value?.split(',')[1]).format('DD-MMM-YY')}` || ''

    useEffect(() => {
        if (props?.snapShotData?.length) {
            let snapQualityToBeSet = props.snapShotData?.[0].qualityDetails?.qualityData ? props.snapShotData[0].qualityDetails.qualityData : []
            setSnapQuality(snapQualityToBeSet?.map((item) => ({ ...item, layCan: laycanDateFormate(item?.layCan) })));
        };
    }, [props.snapShotData]);


    useEffect(() => {
        if (props?.qualityData)
            setQualityData(props.qualityData?.map((item) => ({ ...item, layCan: laycanDateFormate(item.layCan) })));
    }, [props.qualityData]);

    const getQualityDetailsByPcId = (contractId: string, plantCode: string) => {
        const req = { ...new ContractIdRequest(contractId), plantCode };
        service.getQualityDetailsByPcId(req).then(res => {
            if (res.status) {
                setQualityData(res.data?.map((item) => ({ ...item, layCan: laycanDateFormate(item.layCan) })))
            } else {
                AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }

    const hideDrawer = () => {
        setEnableSpecData(false);
        setActivePurchaseId(false);
    }

    // hide model
    const hideModel = () => {
        setEnableSpecData(false);
        setActiveQualityData(undefined);
        setActiveSnapQualityData(undefined);
    }

    const openPurchaseTermData = (quality: IQualityGrid, index: number) => {
        setEnableSpecData(true);
        setActiveQualityData(quality);
        setActiveSnapQualityData(snapQuality?.[index]);
    }

    const openQualityAdjustments = (quality: IQualityGrid, index: number) => {
        setEnableQualityAdjustments(true);
        setActiveQualityData(quality);
        setActiveSnapQualityData(snapQuality?.[index]);
    }

    const hideQualityAdjustments = () => {
        setEnableQualityAdjustments(false);
        setActiveQualityData(undefined);
        setActiveSnapQualityData(undefined);
    }

    const showIndexFormData = (purchaseData: PurchaseDetailsDto) => {
        setActivePurchaseId(purchaseData.typeId || true);
        setActiveIndexedFormData(purchaseData.indexedData);
    }

    const LaycanColumns: any = [

        {
            title: 'Laycan',
            dataIndex: 'layCan',
            // width: 176,
            sorter: (a, b) => a.layCan != null ? a.layCan.localeCompare(b.layCan) : "Have Null Values",
            onCell: (record: any) => ({
                rowSpan: record.rowSpan,
                style: { whiteSpace: 'nowrap' }
            }),
            render: (value, record: any, index) => (
                <span>{valiDateWithSnapShotData(record?.qualitySeq, 'layCan', value )}</span>
              ),
        },
        {
            title: 'Quality',
            dataIndex: 'quality',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, record: any, index) => (
                <span>{valiDateWithSnapShotData(record?.qualitySeq, 'quality', value )}&nbsp;{valiDateWithSnapShotData(record?.qualitySeq, 'qualitySpecifications', record?.qualitySpecifications )}</span>
              ),
        },
        {
            title: 'Purchase Term',
            dataIndex: 'purchasingTerm',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, record: any, index) => (
                <span>{valiDateWithSnapShotData(record?.qualitySeq, 'purchasingTerm', value )}</span>
              ),
        },
        {
            title: 'Spec Standard',
            dataIndex: 'specStandard',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value , record: any, index) => {
                return <a style={{color: '#0083C9', backgroundColor: '#DBF1FD', fontWeight: 500, padding: '4px', borderRadius: '4px', verticalAlign: 'middle'}} onClick={() => openPurchaseTermData(record, index)}>{valiDateWithSnapShotData(record?.qualitySeq, 'specStandard', value )}</a>
            }
        },
        {
            title: 'Quantity MT',
            dataIndex: 'quantity',
            align: 'right',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, record: any, index) => (
                <span>{valiDateWithSnapShotData(record?.qualitySeq, 'quantity', String(Math.round(value)))}</span>
              ),
        },
        {
            title: 'Tolerance',
            dataIndex: 'tolerance',
            align: 'right',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, record: any, index) => {
                return <span>{valiDateWithSnapShotData(record.qualitySeq, 'tolerance', value)}%</span>
            }
        },
        {
            title: 'Mine Name',
            dataIndex: 'mineNameDesc',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, record: any, index) => {
                return <span>{valiDateWithSnapShotData(record.qualitySeq, 'mineNameDesc', value)}</span>
            }
        },
        {
            title: 'Inco Term',
            dataIndex: 'purchaseType',
            render: (value, record: any, index) => {
                return <span>{CheckTypesDiffHandler(record.qualitySeq, record.typeSeq, 'purchaseType', value)}</span>
            }
        },
        {
            title: 'Load Port',
            dataIndex: 'jettyName',
            render: (value, record: any) => {
                return <span>{CheckTypesDiffHandler(record.qualitySeq, record.typeSeq, 'jettyName', value) || CheckTypesDiffHandler(record.qualitySeq, record.typeSeq, 'fasAnchorage', record?.fasAnchorage)}</span>
            }
        },
        {
            title: 'Index Linked',
            dataIndex: 'isIndexLinked',
            render: (value, record: any) => {
                return (
                    <>
                    {(value === true || value === 'Yes') ? 
                    <a style={{ color: '#0083C9', fontWeight: 500 }} onClick={() => showIndexFormData(record)}>Yes</a> : 'No'   
                }
                    </>
                )
            }
        },
        {
            title: 'Price PMT (Excl VAT)',
            dataIndex: 'coalPricePMT',
            align: 'right',
            hidden: !pricePermission,
            render: (value, record: any, index) => {
                return <span>{CheckTypesDiffHandler(record.qualitySeq, record.typeSeq, 'coalPricePMT', value)}</span>
            }
        },
        {
            title: 'VAT',
            align: 'right',
            hidden: noVatPlantCodes,
            render: (value, record: any) => {
                let taxPercentage = vat[0]?.taxPercentage ? vat[0]?.taxPercentage : 0;
                let vatAmount = record?.vat ? record?.initialPricePMT ? (record?.initialPricePMT - record?.coalPricePMT != 0 ? record?.initialPricePMT - record?.coalPricePMT : record?.coalPricePMT - record?.coalPricePMT / (1 + (taxPercentage / 100))) : record?.coalPricePMT - record?.coalPricePMT / (1 + (taxPercentage / 100)) : 0;
                return <>{vatAmount.toFixed(2)}</>
            }
        },        
        {
            title: 'Freight Charges',
            dataIndex: 'freightPricePMT',
            align: 'right',
            render: (value, record: any, index) => {
                return <span>{CheckTypesDiffHandler(record.qualitySeq, record.typeSeq, 'freightPricePMT', value)}</span>
            }
        },
        {
            title: 'FC Charges',
            dataIndex: 'fcCharges',
            align: 'right',
            render: (value, record: any, index) => {
                return <span>{CheckTypesDiffHandler(record.qualitySeq, record.typeSeq, 'fcCharges', value)}</span>
            }
        },
        {
          title: 'Status',
          dataIndex: 'approvalStatus',
          hidden: contractStatus !== 'Active',
          onCell: (record: any) => ({
            rowSpan: record.rowSpan
          }),
          render: (value, record: any) => {
            return <span><StatusTag status={value ?? contractStatus} /></span>
          }
        },
        {
          title: 'Action',
          hidden: mode === 'preview',
          onCell: (record: any) => ({
            rowSpan: record.rowSpan
          }),
          render: (value, record: any) => {
            return (
              <Tooltip title='View Laycan Details'>
                <Button size='middle' icon={<ArrowRightOutlined />} 
                style={{ border: 'none', padding: '8px', borderRadius: '50%', color: '#0295D4', backgroundColor: '#DEECF3' }}
                onClick={() => viewLaycanHandler(record?.qualityId)}
                ></Button>
              </Tooltip>
            )
          }
        }

    ]

    // const filteredColumns = pricePermission
    // ? LaycanColumns 
    // : LaycanColumns.filter(column => column.dataIndex !== 'coalPricePMT');

    const laycanTableDataProcessing = (qualityData: any[]) => {
            const laycanData = [];
        
        qualityData?.forEach((rec) => {
            rec.purchaseTypeData?.forEach((purchaseTypeRecord, index) => {
                laycanData.push({
                    purchaseType: purchaseTypeRecord.purchaseType,
                    jettyName: purchaseTypeRecord.jettyName,
                    fasAnchorage: purchaseTypeRecord.fasAnchorage,
                    fcCharges: purchaseTypeRecord.fcCharges,
                    freightPricePMT: purchaseTypeRecord.freightPricePMT,
                    coalPricePMT: purchaseTypeRecord.coalPricePMT,
                    initialPricePMT: purchaseTypeRecord.initialPricePMT,
                    isIndexLinked: purchaseTypeRecord.isIndexLinked,
                    indexedData: purchaseTypeRecord.indexedData,
                    vat: purchaseTypeRecord.vat,
                    typeSeq: purchaseTypeRecord.typeSeq,
                    rowSpan: index === 0 ? rec.purchaseTypeData.length : 0, 
                    quality: rec.quality,
                    purchaseTermData: rec.purchaseTermData,
                    quantity: rec.quantity,
                    tolerance: rec.tolerance,
                    mineNameDesc: rec.mineNameDesc,
                    purchasingTerm: rec.purchasingTerm,
                    specStandard: rec.specStandard,
                    layCan: rec.layCan,
                    qualitySpecifications: rec.qualitySpecifications,
                    qualitySeq: rec.qualitySeq,
                    approvalStatus: rec.approvalStatus,
                    qualityId: rec.qualityId,
                });
            });
        });
    
        return laycanData;
    };

    // const columns = ['Laycan', 'Quality', 'Purchase Term', 'Spec Standard', 'Quantity MT', 'Tolerance', 'Mine Name', 'Inco Term', 'Load Port', 'Index Linked ', 'Price PMT (Excl VAT)', 'VAT', 'Freight PMT', 'FC Charges']

    const cancelHandler = () => {
        setActivePurchaseId(null);
        setActiveIndexedFormData(null);
    }

    const onChange = (key: SpecTypesEnum) => {
        setActiveSpecType(key);
    };

    const assignObjectValues = (childObject: any, parentObject: any) => {
        Object.keys(childObject).forEach(key => {
            childObject[key] = parentObject?.[key];
        })
        return childObject;
    }


    // const rowClassName = (record, qualitySeq) => {
    //     if (!snapQuality.some((item) => item?.qualitySeq === qualitySeq)) {
    //         if (contractType === ContractTypesEnum.ADDENDUM) {
    //             return 'highlighted-row';
    //         } else {
    //             return '';
    //         }
    //     }
    //     return '';
    // };

    const rowClassName = (row: any, index) => {
        return index % 2 === 0 ? 'row-even' : 'row-odd'; 
      };
    
    return (
    
         <div style={{ overflowX: 'auto' }}>
            <Table 
                className='purchase-Details'
                    columns={LaycanColumns}
                    dataSource={laycanTableDataProcessing(showClosedLaycans ? qualityData : qualityData.filter((item) => item.approvalStatus !== 'Closed'))}
                    pagination={false}
                    rowClassName={rowClassName}
                    style={{overflowX: 'auto'}}
                >
            </Table>
            
            <div>
              {qualityData.filter((item) => item.approvalStatus === 'Closed').length > 0 && 
                <Button type='link' onClick={() => setShowClosedLaycans(!showClosedLaycans)}>
                  {showClosedLaycans ? 'Hide Closed Laycans' : 'Show Closed Laycans'}
                </Button>}
            </div>

            {/* <div className='table-responsive'>
                <table className='table table-sm table-borderless'>
                    <thead className='table-header' style={{ background: '#F2F7FD', border: '4px solid #FFFFFF' }}>                      
                        {columns.map((item) => (
                        <th style={{ paddingLeft: '6px', verticalAlign: 'middle' }} key={item}>
                            {item === 'Laycan' ? (
                                <button 
                                    onClick={handleSort} 
                                    style={{ 
                                        background: 'none', 
                                        border: 'none', 
                                        cursor: 'pointer',
                                        outline: 'none' 
                                    }}
                                >
                                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>{item}</span> 
                                    <span style={{ fontSize: '12px', marginLeft: '4px', }}>
                                        {isAscending ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} /> }
                                    </span> 
                                </button>
                            ) : (
                                item
                            )}
                        </th>
                    ))}

                        </thead>
                    <tbody style={{marginTop:'10px', paddingTop: '10px'}}>
                        {qualities?.map((e, index) => e.purchaseTypeData.map((item, idx) => {
                            let taxPercentage = vat[0]?.taxPercentage ? vat[0]?.taxPercentage : 0;
                            let vatAmount = item.vat ? item.initialPricePMT ? (item.initialPricePMT - item.coalPricePMT != 0 ? item.initialPricePMT - item.coalPricePMT : item.coalPricePMT - item.coalPricePMT / (1 + (taxPercentage / 100))) : item.coalPricePMT - item.coalPricePMT / (1 + (taxPercentage / 100)) : 0;
                            return (
                                <tr key={item.id} style={{ backgroundColor: index % 2 === 0 ? '#F8FBFF' : '#FFFFFF', border: '4px solid #FFFFFF', borderRadius: '20px'}} className={rowClassName(e, e.qualitySeq)}>
                                    {idx === 0 &&
                                        <td className=' font-bold' style={{verticalAlign:'middle', minWidth: '176px'}} rowSpan={e.purchaseTypeData.length}>{valiDateWithSnapShotData(e.qualitySeq, 'layCan', e?.layCan)}</td>
                                    }
                                    {idx === 0 &&
                                        <td style={{verticalAlign:'middle'}} rowSpan={e.purchaseTypeData.length}>{valiDateWithSnapShotData(e.qualitySeq, 'quality', e?.quality)}&nbsp;{e?.qualitySpecifications}</td>
                                    }
                                    {idx === 0 &&
                                        <td style={{verticalAlign:'middle'}} rowSpan={e.purchaseTypeData.length}>{valiDateWithSnapShotData(e.qualitySeq, 'purchasingTerm', e?.purchasingTerm)}</td>
                                    }
                                    {idx === 0 &&

                                      <td className='text-uppercase text-center'style={{verticalAlign:'middle'}} rowSpan={e.purchaseTypeData.length}

                                        >
                                        <><a style={{color: '#0083C9', backgroundColor: '#DBF1FD', fontWeight: 500, padding: '4px', borderRadius: '4px', verticalAlign: 'middle'}} onClick={() => openPurchaseTermData(e, index)}>{valiDateWithSnapShotData(e.qualitySeq, 'specStandard', e.specStandard)}</a>
                                        <span>||</span><a style={{color: '#0083C9', backgroundColor: '#DBF1FD', fontWeight: 500, padding: '4px', borderRadius: '4px'}} onClick={() => openQualityAdjustments(e, index)}>Q-A</a>
                                           ||{e.purchasingTerm === ContractTermEnum.SPEC && e.noPriceAdjustment ? <span className='text-warning'>No Price Adjustment</span> : ''}
                                        </>
                                      </td>
                                    }
                                    {idx === 0 &&
                                        <td className='text-uppercase' style={{textAlign: 'right', verticalAlign:'middle'}} rowSpan={e.purchaseTypeData.length}>{valiDateWithSnapShotData(e.qualitySeq, 'quantity', String(Math.round(e?.quantity)))}</td>
                                    }
                                    {idx === 0 &&
                                        <td className='font-bold' style={{textAlign: 'right', verticalAlign:'middle'}}rowSpan={e.purchaseTypeData.length}>{valiDateWithSnapShotData(e.qualitySeq, 'tolerance', e?.tolerance)}%</td>
                                    }
                                    {idx === 0 &&
                                        <td className='text-uppercase'  style={{verticalAlign:'middle'}} rowSpan={e.purchaseTypeData.length}>{valiDateWithSnapShotData(e.qualitySeq, 'mineNameDesc', e?.mineNameDesc)}</td>
                                    }
                                    <td style={{verticalAlign:'middle'}}>{CheckTypesDiffHandler(e.qualitySeq, item.typeSeq, 'purchaseType', item.purchaseType)}</td>
                                    <td className='text-uppercase'>{CheckTypesDiffHandler(e.qualitySeq, item.typeSeq, 'jettyName', item.jettyName || item.fasAnchorage)}</td>
                                    
                                    <td className='text-uppercase' style={{verticalAlign:'middle'}}> {(item.isIndexLinked === true || item.isIndexLinked === 'Yes') ?
                                        <a style={{color: '#0083C9', fontWeight: 500}} onClick={() => showIndexFormData(item)}>Yes</a>
                                        : 'No'
                                    }</td>
                                    <td style={{textAlign: 'right', verticalAlign:'middle'}}>{isPermissionExist([99, 114]) && CheckTypesDiffHandler(e.qualitySeq, item.typeSeq, 'coalPricePMT', item.coalPricePMT)}</td>
                                    <td style={{textAlign: 'right', verticalAlign:'middle'}}>{vatAmount.toFixed(2)}</td>
                                    <td style={{textAlign: 'right', verticalAlign:'middle'}}>{CheckTypesDiffHandler(e.qualitySeq, item.typeSeq, 'freightPricePMT', item.freightPricePMT)}</td>
                                    <td style={{textAlign: 'right', verticalAlign:'middle'}}>{CheckTypesDiffHandler(e.qualitySeq, item.typeSeq, 'fcCharges', item.fcCharges)}</td>
                                    <td className='text-uppercase text-center'>{CheckTypesDiffHandler(e.qualitySeq, item.typeSeq, 'exportDocumentation', item.exportDocumentation)}</td>
                                    <td className='text-uppercase text-center'>{CheckTypesDiffHandler(e.qualitySeq, item.typeSeq, 'businessNumber', item.businessNumber)}</td>

                                    
                                    
                                </tr>
                            )
                        }
                        ))}
                    </tbody>
                </table>
            </div> */}
            {/* <Table
                columns={qualityColumns}
                dataSource={qualityData}
                pagination={false}
                bordered
                rowClassName={rowClassName}
                rowKey={(record) => record.typeId}
            /> */}
            <Drawer
                open={enableSpecData}
                className='dashborad-popup'
                width={800}
                title={
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <span style={{ fontSize: '20px', fontWeight: '600', color: '#323F49' }}>Specification Standard</span>
                        <Button style={{border: 'none', fontSize: '20px', color: '#6B7280', boxShadow: 'none'}} icon={<FontAwesomeIcon icon={faXmark} />} onClick={hideDrawer}></Button>
                    </div>
                } 
                closable={false}
                onClose={hideDrawer}
                footer={
                    <div style={{ display: 'flex', justifyContent: 'flex-end', color: '#0083C9', fontSize: '14px', fontWeight: 400, margin: '8px'}}>
                        <a onClick={hideDrawer} >Close</a>
                    </div>
                }
            >

                    <div>
                        <span style={{fontSize: '16px', fontWeight: 600, color: '#64748B'}}>Analysis Type</span>
                    </div>
                        <span style={{fontSize: '16px', fontWeight: 600, color: '#323F49'}}>{activeQualityData?.specStandard}</span>
                        
                    <div style={{ marginTop: '20px' }}>    
                    {qualityData[0]?.specStandard == SpecTypesEnum.BOTH || qualityData[0]?.specStandard == SpecTypesEnum.ASTM ? (
                            <SpecsViewPage purchaseTermData={activeQualityData?.purchaseTermData} specType={SpecTypesEnum.ASTM} contractType={contractType} snapShotPurchaseTermData={activeSnapQualityData?.purchaseTermData} snapSpecType={SpecTypesEnum.ASTM} /> ) :
                            <SpecsViewPage purchaseTermData={activeQualityData?.purchaseTermData} specType={SpecTypesEnum.ISO} contractType={contractType} snapShotPurchaseTermData={activeSnapQualityData?.purchaseTermData} snapSpecType={SpecTypesEnum.ISO} /> }
                    </div>

                    {/* {qualityData[0]?.specStandard == SpecTypesEnum.BOTH ? <Tabs onChange={onChange} activeKey={activeSpecType} centered>
                        <TabPane tab={SpecTypesEnum.ASTM} key={SpecTypesEnum.ASTM}>
                            <SpecsViewPage purchaseTermData={activeQualityData?.purchaseTermData} specType={SpecTypesEnum.ASTM} contractType={contractType} snapShotPurchaseTermData={activeSnapQualityData?.purchaseTermData} snapSpecType={SpecTypesEnum.ASTM} />
                        </TabPane>
                        <TabPane tab={SpecTypesEnum.ISO} key={SpecTypesEnum.ISO}>
                            <SpecsViewPage purchaseTermData={activeQualityData?.purchaseTermData} specType={SpecTypesEnum.ISO} contractType={contractType} snapShotPurchaseTermData={activeSnapQualityData?.purchaseTermData} snapSpecType={SpecTypesEnum.ISO} />
                        </TabPane>
                    </Tabs> : <SpecsViewPage purchaseTermData={activeQualityData?.purchaseTermData} specType={activeQualityData?.specStandard} contractType={contractType} snapShotPurchaseTermData={activeSnapQualityData?.purchaseTermData} snapSpecType={activeSnapQualityData?.specStandard} />}                 */}

            </Drawer>
            <Modal
                open={enableQualityAdjustments}
                className='dashborad-popup'
                width='70%'
                title=''
                style={{ top: 0 }}
                bodyStyle={{ height: '90vh', overflowY: 'auto' }}
                onCancel={hideQualityAdjustments}
                footer={<></>}
            >
                {activeQualityData?.qualityAdjustments ? <pre>
                    {activeQualityData.qualityAdjustments}
                </pre> : <Empty />}
            </Modal>
            <Drawer
                width={800}
                open={activePurchaseId ? true : false}
                key={activePurchaseId}
                title={
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <span style={{ fontSize: '20px', fontWeight: '600', color: '#323F49' }}>Index Linked</span>
                        <Button style={{border: 'none', fontSize: '20px', color: '#6B7280', boxShadow: 'none'}} icon={<FontAwesomeIcon icon={faXmark} />} onClick={hideDrawer}></Button>
                    </div>
                } 
                closable={false}
                onClose={hideDrawer}
                footer={
                    <div style={{ display: 'flex', justifyContent: 'flex-end', color: '#0083C9', fontSize: '14px', fontWeight: 400, margin: '8px'}}>
                        <a onClick={hideDrawer} >Close</a>
                    </div>
                }
            >
                <CoalIndexView indexedData={activeIndexedFormData} cancelHandler={cancelHandler} />
            </Drawer>
        </div>
        
    )
}

export default QualityDetailView;