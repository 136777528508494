import { BusinessNumberService, ContractIdRequest, ContractModesEnum } from '@exportx/shared-models-and-services'
import { Table, Typography } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthState } from '../../../common'
import { render } from '@testing-library/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { ColumnsType } from 'antd/es/table'
import Link from 'antd/lib/typography/Link'

interface bnIProps {
    contractId: string;
    pcQuantityTotal: number;
    contractType?: ContractModesEnum;
    allocateData?:any;
    contractDetails?:any;
}

const AssociatedBusinessNumbers = (props: bnIProps) => {
    const { contractId, pcQuantityTotal, contractType, allocateData, contractDetails } = props
    const navigate = useNavigate();
    // GetContractAssociatedBusinessNumberDto    // businessNumberData state data type
    const { Text } = Typography;

    let pathToreDirect = '/barge-detail-view';
    const redirectToFullView = (bargeId: string) => {
        navigate(pathToreDirect, { state: { bargeId } });
    }

    const toLocaleString = (value) => {
        return Number(value).toLocaleString();
    }

    // const allocationTotal = businessNumberData?.businessNoData.reduce((a, c) => a + Number(c.allocatedQuantity), 0);
    // const deliveredTotals = businessNumberData?.unassignedBusinessNoData.reduce((a, c) => a + Number(c.bargeQuantity), 0);
    // const deliveredBusiness = businessNumberData?.businessNoData.reduce((a, c) => a + Number(c.deliveredQuantity), 0)
    // const deliveredUnAssignQuantity = businessNumberData?.unassignedPartialQuantityData.reduce((a, c) => a + Number(c.remainingPartialQuantity), 0)
    // const deliveredTotal = Number(deliveredTotals) + Number(deliveredBusiness) + Number(deliveredUnAssignQuantity)
    // const availableQty = Number(pcQuantityTotal) - Number(deliveredTotal);
    // return (
    //     <>
    //         <table className="table table-bordered table-sm">
    //             <thead>
    //                 <tr>
    //                     <th className="text-center" colSpan={5}>
    //                         Associated Business Numbers
    //                     </th>
    //                 </tr>
    //                 <tr className="table-header">
    //                     <th className=" text-left">Business No</th>
    //                     <th className="text-left">Quality</th>
    //                     <th className="text-right">Contract Quantity</th>
    //                     <th className="text-right">Quantity Allocated</th>
    //                     <th className="text-right">Quantity Delivered</th>
    //                 </tr>
    //             </thead>
    //             <tbody>
    //                 {businessNumberData?.businessNoData.map((e, index) => (
    //                     <tr key={index}>
    //                         <td className="text-left">
    //                             <Link onClick={() => { navigate('/businessnumber') }}>
    //                                 {e.businessNo}{" "}
    //                                 {e.motherVesselName
    //                                     ? "( " + e.motherVesselName + " )"
    //                                     : ""}

    //                             </Link>

    //                         </td>
    //                         <td className="text-left">{e.quality}</td>
    //                         <td className="text-right">
    //                             {e.contractQuantity
    //                                 ? toLocaleString(e.contractQuantity)
    //                                 : ""}
    //                         </td>
    //                         <td className="text-right">
    //                             {e.allocatedQuantity
    //                                 ? toLocaleString(e.allocatedQuantity)
    //                                 : ""}
    //                         </td>
    //                         <td className="text-right">
    //                             {e.deliveredQuantity
    //                                 ? toLocaleString(e.deliveredQuantity)
    //                                 : ""}
    //                         </td>
    //                     </tr>
    //                 ))}
    //                 {businessNumberData?.unassignedPartialQuantityData.map(
    //                     (e, index) => (
    //                         <tr key={index}>
    //                             <td className="text-left">
    //                                 Unassigned Partial Quantity
    //                             </td>
    //                             <td className="text-left">{e.quality}</td>
    //                             <td className="text-right">
    //                                 {e.contractQuantity
    //                                     ? toLocaleString(e.contractQuantity)
    //                                     : ""}
    //                             </td>
    //                             <td className="text-right">-</td>
    //                             <td className="text-right">
    //                                 {e.remainingPartialQuantity
    //                                     ? toLocaleString(
    //                                         e.remainingPartialQuantity
    //                                     )
    //                                     : 0}
    //                             </td>
    //                         </tr>
    //                     )
    //                 )}
    //                 {businessNumberData?.unassignedBusinessNoData.map(
    //                     (e, index) => (
    //                         <tr key={index}>
    //                             <td className="text-left">
    //                                 Unassigned &nbsp;{" "}
    //                                 <a className="link-primary" onClick={() => redirectToFullView(e.bgId)}>
    //                                     {SequenceUtils.formatNumberToSpecificLength(e.bargeId)}({e.bargeNomination})
    //                                 </a>
    //                             </td>
    //                             <td className="text-left">{e.quality}</td>
    //                             <td className="text-right">
    //                                 {e.contractQuantity
    //                                     ? toLocaleString(e.contractQuantity)
    //                                     : ""}
    //                             </td>
    //                             {/* <td className='text-right'>{e.allocated_quantity ? toLocaleString(e.allocated_quantity) : ""}</td> */}
    //                             <td className="text-right">-</td>
    //                             <td className="text-right">
    //                                 {e.bargeQuantity
    //                                     ? toLocaleString(e.bargeQuantity)
    //                                     : 0}
    //                             </td>
    //                         </tr>
    //                     )
    //                 )}
    //                 {(businessNumberData?.businessNoData.length > 0 ||
    //                     businessNumberData?.unassignedBusinessNoData.length > 0 ||
    //                     businessNumberData?.unassignedPartialQuantityData.length >
    //                     0) && (
    //                         <tr>
    //                             <td colSpan={3}></td>
    //                             <td
    //                                 className="text-right"
    //                                 style={{ fontWeight: "bold" }}
    //                             >
    //                                 Total :{" "}
    //                                 {
    //                                     allocationTotal
    //                                         ? toLocaleString(
    //                                             allocationTotal
    //                                         )
    //                                         : 0}
    //                             </td>
    //                             <td
    //                                 className="text-right"
    //                                 style={{ fontWeight: "bold" }}
    //                             >
    //                                 Totals :{" "}
    //                                 {deliveredTotal
    //                                     ? toLocaleString(
    //                                         deliveredTotal
    //                                     )
    //                                     : 0}
    //                             </td>
    //                         </tr>
    //                     )}

    //                 {(businessNumberData?.businessNoData.length > 0 ||
    //                     businessNumberData?.unassignedBusinessNoData.length > 0 ||
    //                     businessNumberData?.unassignedPartialQuantityData.length >
    //                     0) && (
    //                         <tr>
    //                             <td colSpan={4}></td>
    //                             <td
    //                                 className="text-right text-success"
    //                                 style={{ fontWeight: "bold" }}
    //                             >
    //                                 Remaining : &nbsp;
    //                                 {availableQty
    //                                     ? toLocaleString(
    //                                         availableQty
    //                                     )
    //                                     : 0}
    //                             </td>
    //                         </tr>
    //                     )}
    //             </tbody>
    //         </table>

    //     </>

    // )

    // const columns: any[] = [
    //     {
    //         title: 'Business No',
    //         dataIndex: 'businessNo',
    //         render: (value, record) => {
    //             let href = ''
    //             if (record.assigned === 'assigned') {
    //                 href = `/#/bn-detail-view?bn_Id=${record.businessNoId}`
    //             };
    //             if (record.assigned === 'unAssigned') {
    //                 href = `/#/barge-detail-view?barge_id=${record.bgUid}`
    //             };

    //             return <>
    //                 <a href={href} className="link-primary">{value}</a>
    //             </>
    //         }
    //     },
    //     {
    //         title: 'Laycan',
    //         dataIndex: 'laycan',
    //         align: 'center',
    //         render: (value, record) => {
    //             return <>
    //                 {moment(record.laycanFromDate).format('DD/MM/YYYY') + " - " + moment(record.laycanToDate).format('DD/MM/YYYY')}
    //             </>
    //         }
    //     },
    //     {
    //         title: 'Quality',
    //         dataIndex: 'quality',
    //         align: 'center',
    //     },
    //     {
    //         title: 'Quantity',
    //         dataIndex: 'quantity_in_mt',
    //         align: 'center',
    //         render: (value) => {
    //             if(value !== null){
    //                 return <>
    //                 {value} MT
    //                 </>
    //             }
    //         }
    //     },
    //     {
    //         title: 'Quantity Allocated',
    //         dataIndex: 'totalAllocatedQty',
    //         align: 'center',
    //         render: (value) => {
    //             if(value !== null){
    //                 return <>
    //                 {value} MT
    //                 </>
    //             }
    //         }
    //     },
    //     {
    //         title: 'Quantity Delivered',
    //         // title: 'Delivered',
    //         dataIndex: 'redraftQty',
    //         align: 'center',
    //         render: (value) => {
    //             if(value !== null){
    //                 return <>
    //                 {value} MT
    //                 </>
    //             }
    //         }
    //     },
    //     {
    //         title: 'Reaming',
    //         dataIndex: 'redraftQty',
    //         align: 'center',
    //         render: (value, record) => {
    //             const reaming = Number(record.contractQuantity) - Number(record.redraftQty)
    //             return <>
    //                 {Number(reaming).toFixed(2)} MT
    //             </>
    //         }
    //     },
    // ]

    const AllocationColumns: ColumnsType<any> = [

        {
            title: 'Laycan',
            dataIndex: 'laycanFromDate',
            sorter: (a, b) => a.laycanFromDate != null ? a.laycanFromDate.localeCompare(b.laycanFromDate) : "Have Null Values",
            onCell: (record: any) => ({
                rowSpan: record.rowSpan,
                style: { whiteSpace: 'nowrap' }
            }),
            render: (value, record: any, index) => {
                return <>{moment(record.laycanFromDate).format('DD-MMM-YY')} to {moment(record.laycanToDate).format('DD-MMM-YY')}</>
            }
        },
        {
            title: 'Quality',
            dataIndex: 'quality',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, record: any, index) => {
                return <span>{value}&nbsp;{record.qualitySpecifications}</span>;
            }
        },
        {
            title: 'Quantity MT',
            dataIndex: 'quantity_in_mt',
            align: 'right',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value) => {
                return <>
                {Math.round(value)}
                </>
            }
        },
        {
            title: 'Inco Term',
            dataIndex: 'incoTerm',

        },
        {
            title: 'Price PMT (Excl VAT)',
            dataIndex: 'pricePmt',
            align: 'right',

        },
        {
            title: 'Business No',
            dataIndex: 'business_no',
            render: (value, record: any) => {
                const link = `/#/bn-detail-view?bn_Id=${record.business_no_id}`
                return <> {
                    <Link href={link} style={{color: '#0083C9', fontWeight: 500}} >{record.businessNo}</Link>
                }
                </>
            }

        },
        {
            title: 'Delivered',
            dataIndex: 'totalDelivered',
            align: 'right',
            render: (value) => {
                return <>
                {parseFloat(value).toFixed(3)}
                </>
            }
        },
    ]

    const allocationTableDataProcessing = (allocationData: any[]) => {
        console.log('allocationdata===', allocationData);
        const data = [];
    
        allocationData?.forEach((rec) => {
        let type = rec.salesTypes ? rec.salesTypes : rec.purchaseTypes
        type?.forEach((purchaseTypeRecord, index) => {
            data.push({
                incoTerm: purchaseTypeRecord.incoTerm,
                pricePmt: purchaseTypeRecord.pricePmt,
                quantity_allocated: purchaseTypeRecord.quantity_allocated,
                businessNo: purchaseTypeRecord.businessNo,
                business_no_id: purchaseTypeRecord.business_no_id,
                totalDelivered: purchaseTypeRecord.totalDelivered,
                rowSpan: index === 0 ? type.length : 0, 
                laycanFromDate: rec.laycanFromDate,
                laycanToDate: rec.laycanToDate,
                quality: rec.quality,
                quantity_in_mt: rec.quantity_in_mt,
                qualitySpecifications: rec.qualitySpecifications,
            });
        });
    });

    return data;
};

    // const columns = ['Laycan', 'Quality', 'Quantity MT', 'Inco Term', 'Price PMT (Excl VAT)', 'Business No', 'Delivered']


    let delivered = 0; 

    allocateData?.allocationData?.forEach(e => {
        let type = e.salesTypes ? e.salesTypes : e.purchaseTypes
        type?.forEach(item => {
            delivered += parseFloat(item.totalDelivered); 
        });
    });

    const Balance = (Number(contractDetails?.totalQty) - (Number(delivered) + Number(allocateData?.unAssignedBargeQty)));

    const rowClassName = (row: any, index) => {
        return index % 2 === 0 ? 'row-even' : 'row-odd'; 
      };

      const footer = () => (
        <div style={{ background: '#F2F7FD', padding: '8px 0px'}}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'right'}}>
                <div style={{marginRight: '35px'}}>
                    <div><b>Total Delivered:</b></div>
                    <div><b>Unassigned Qty:</b></div>
                    <div><b>Balance:</b></div>
                </div>
                <div style={{marginRight: '10px'}}>
                    <div style={{color: '#0283C8'}}><b>{parseFloat(delivered.toFixed(3))}</b></div>
                    <div style={{color: 'green'}}><b>{allocateData?.unAssignedBargeQty}</b></div>
                    <div style={{color: '#FF6F61'}}><b>{parseFloat(Balance.toFixed(3))}</b></div>
                </div>
            </div>
        </div>
    );

    return <div style={{ overflowX: 'auto' }}>

                <Table 
                    className='purchase-Details'
                    columns={AllocationColumns}
                    dataSource={allocationTableDataProcessing(allocateData?.allocationData)}
                    pagination={false}
                    rowClassName={rowClassName}
                    style={{overflowX: 'auto'}}
                    footer={footer}
                >
                </Table>


                {/* <table className='table table-sm table-borderless'>
                    <thead className='table-header' style={{ background: '#F2F7FD', border: '4px solid #FFFFFF' }}>
                    {columns.map((item) => (
                        <th style={{ paddingLeft: '6px' }} key={item}>
                            {item === 'Laycan' ? (
                                <button 
                                    onClick={handleSort} 
                                    style={{ 
                                        background: 'none', 
                                        border: 'none', 
                                        cursor: 'pointer',
                                        outline: 'none' 
                                    }}
                                >
                                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>{item}</span> 
                                    <span style={{ fontSize: '12px', marginLeft: '4px', }}>
                                        {isAscending ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} /> }
                                    </span> 
                                </button>
                            ) : (
                                item
                            )}
                        </th>
                    ))}

                    </thead>
                        <tbody style={{marginTop:'10px', paddingTop: '10px'}}>
                            {sortedData?.map((e, index) => e.purchaseTypes.map((item, idx) => {
                                return (
                                    <tr key={item.id} style={{ backgroundColor: index % 2 === 0 ? '#F8FBFF' : '#FFFFFF', border: '4px solid #FFFFFF', borderRadius: '20px', paddingLeft: '12px'}} >
                                        {idx === 0 &&
                                            <td className='font-bold' style={{verticalAlign:'middle', minWidth: '176px'}} rowSpan={e.purchaseTypes.length}>{moment(e.laycanFromDate).format('DD-MMM-YY')} to {moment(e.laycanToDate).format('DD-MMM-YY')}</td>
                                        }
                                        {idx === 0 &&
                                            <td style={{textAlign: 'right', verticalAlign:'middle'}} rowSpan={e.purchaseTypes.length}>{e.quality}&nbsp;{e.qualitySpecifications}</td>
                                        }
                                        
                                        {idx === 0 &&
                                            <td style={{textAlign: 'right', verticalAlign:'middle'}} rowSpan={e.purchaseTypes.length}>{Math.floor(e.quantity_in_mt)}</td>
                                        }
                                        
                                        <td >{item.incoTerm}</td>
                                        <td style={{textAlign: 'right', verticalAlign:'middle'}} >{item.pricePmt}</td>
                                        <td className='text-uppercase '><a href={`/#/bn-detail-view?bn_Id=${item.business_no_id}`} style={{color: '#0083C9', fontWeight: 500}}>{item.business_no}</a></td>
                                        <td style={{textAlign: 'right', verticalAlign:'middle'}} >{Math.floor(item.totalDelivered)}</td>                          
                                        
                                    </tr>
                                )
                            }
                            ))}
                        </tbody>
                            <tfoot style={{ background: '#F2F7FD', border: '4px solid #FFFFFF' }}>
                                <tr>
                                    <td colSpan={6} style={{ textAlign: 'right' }}>
                                        <div><b>Total Delivered:</b></div>
                                        <div><b>Balance:</b></div>
                                        <div>(Contract Qty - Delivered)</div>
                                    </td>
                                    
                                    <td colSpan={1} style={{ textAlign: 'right' }}>
                                        <div><b>{Math.floor(delivered)}</b></div>
                                        <div><b>{Math.ceil(Balance)}</b></div>
                                    </td>
                                </tr>
                            </tfoot>
                </table> */}

        {/* <Table
            className='purchase-Details'
            columns={columns}
            dataSource={businessNumberData}
            style={{overflowX: 'auto'}}
            // bordered={true}
            bordered={false}
            pagination={false}
            footer={() => (
                <div style={{textAlign: 'right', backgroundColor: '#F2F7FD', color: '#40474F'}}>
                  <div><b>Total Delivered: </b></div>
                  <div><b>Balance: </b></div>
                  <div>(Contract Qty - Deliverd)</div>
                </div>
              )}
            // summary={(pageData) => {
            //     let totalAllocatedQty = 0;
            //     let reaming = 0;
            //     let redraftQty = 0;
            //     pageData.forEach((rec) => {
            //         totalAllocatedQty += rec.totalAllocatedQty ? Number(rec.totalAllocatedQty) : 0;
            //         reaming += Number(rec.contractQuantity) - Number(rec.redraftQty);
            //         redraftQty += Number(rec.redraftQty);
            //     });
            //     return (
            //         <>
            //             <Table.Summary.Row className='tableFooter'>
            //                 <Table.Summary.Cell index={1} colSpan={4}> </Table.Summary.Cell>
            //                 <Table.Summary.Cell index={2} colSpan={1}>
            //                     <Text><b >Allocated Total :</b><span style={{paddingLeft:'10px',marginRight:'20px'}}>{totalAllocatedQty.toFixed(2)}</span></Text>
            //                     </Table.Summary.Cell>
            //                 <Table.Summary.Cell index={3} colSpan={1}><Text><b>Deliver Total :</b>{redraftQty.toFixed(2)}</Text></Table.Summary.Cell>
            //                 <Table.Summary.Cell index={3} colSpan={2}><Text><b>Total :</b>{reaming.toFixed(2)}</Text></Table.Summary.Cell>
            //             </Table.Summary.Row>

            //         </>
            //     )
            // }}
        >

        </Table> */}



    </div>
}

export default AssociatedBusinessNumbers