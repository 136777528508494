import React from 'react';
import { Form, DatePicker, Input, Button, Row, Col } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

interface PricingFormulaProps {
  formRef: any;
  initialValues?: any;
}

export const PricingFormula: React.FC<PricingFormulaProps> = ({ formRef, initialValues }) => {
  // Transform initial values to ensure dates are dayjs objects
  const transformedInitialValues = initialValues ? {
    ...initialValues,
    pricingFormulas: initialValues.pricingFormulas?.map(formula => ({
      ...formula,
      startDate: formula.startDate ? dayjs(formula.startDate) : null,
      endDate: formula.endDate ? dayjs(formula.endDate) : null
    })).sort((a, b) => {
      if (!a.startDate) return 1;
      if (!b.startDate) return -1;
      return b.startDate.unix() - a.startDate.unix();
    })
  } : initialValues;

  return (
    <Form
      form={formRef}
      initialValues={transformedInitialValues}
      autoComplete="off"
    >
      <Form.List name="pricingFormulas">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Row key={key} gutter={[16, 16]} style={{ marginBottom: 16 }}>
                <Col xs={24} sm={12} md={6} lg={6}>
                  <Form.Item
                    {...restField}
                    name={[name, 'startDate']}
                    rules={[{ required: true, message: 'Start date is required' }]}
                  >
                    <DatePicker placeholder="Start Date" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6} lg={6}>
                  <Form.Item
                    {...restField}
                    name={[name, 'endDate']}
                    rules={[{ required: true, message: 'End date is required' }]}
                  >
                    <DatePicker placeholder="End Date" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={10} lg={10}>
                  <Form.Item
                    {...restField}
                    name={[name, 'formula']}
                    rules={[{ required: true, message: 'Formula is required' }]}
                  >
                    <Input.TextArea 
                      placeholder="Enter Pricing Formula" 
                      autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={2} lg={2} style={{ display: 'flex', alignItems: 'center' }}>
                  <MinusCircleOutlined onClick={() => remove(name)} style={{ fontSize: '16px', color: '#ff4d4f' }} />
                </Col>
              </Row>
            ))}
            <Form.Item style={{ marginTop: 8 }}>
              <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />} style={{ minWidth: '160px' }}>
                Add Pricing Formula
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};
