import { Card, Col, Divider, List, Row, Typography } from 'antd';
import { PricingFormulaDto } from '@exportx/shared-models-and-services';
import dayjs from 'dayjs';

interface IPricingFormualaViewProps {
  pricingFormulas: PricingFormulaDto[];
}

export const PricingFormulaView = (props: IPricingFormualaViewProps) => {
  const { pricingFormulas = [] } = props;

  const sortedPricingFormulas = [...pricingFormulas].sort((a, b) => 
    dayjs(b.startDate).valueOf() - dayjs(a.startDate).valueOf()
  );

  return (
    <List
      dataSource={sortedPricingFormulas}
      renderItem={(formula, index) => (
        <List.Item
          key={index}
          style={{ border: 'none', padding: '8px 0' }}
        >
          <div>
            <Typography.Text strong>
              Period: {dayjs(formula.startDate).format('DD-MMM-YYYY')} to{' '}
              {dayjs(formula.endDate).format('DD-MMM-YYYY')}
            </Typography.Text>
            <Typography.Paragraph
              style={{
                whiteSpace: 'pre-wrap',
                marginTop: 4,
                marginBottom: 0,
              }}
            >
              {formula.formula}
            </Typography.Paragraph>
          </div>
        </List.Item>
      )}
    />
  );
};
