import { ContractTypesEnum, IndexedDataDto, LcTypeEnum, PaymentModesEnum, SpecTypesEnum, VesselTypesEnum } from "@exportx/shared-models-and-services";
import { Button, Descriptions, Drawer } from "antd";
import moment from "moment";
import { useState } from "react";
import { CoalIndexView } from "../../masters/components/coal-index-prices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import SpecsViewPage from "./specs/specs-view-page";

interface ILaycanViewProps {
  laycanData: any;
  contractType: ContractTypesEnum;
}

export const LaycanView = (props: ILaycanViewProps) => {
  const { laycanData, contractType } = props;

  const [showIndexedData, setShowIndexedData] = useState(false);
  const [activeIndexedFormData, setActiveIndexedFormData] = useState<IndexedDataDto>(null);
  const [showSpecsData, setShowSpecsData] = useState(false);

  const laycanDateFormate = (value: any) => value && Array.isArray(value) ? `${moment(value[0]).format('DD-MMM-YYYY')} to ${moment(value[1]).format('DD-MMM-YYYY')}` : `${moment(value?.split(',')[0]).format('DD-MMM-YYYY')} to ${moment(value?.split(',')[1]).format('DD-MMM-YYYY')}` || ''

  const showIndexFormData = (indexedData: any) => {
    setActiveIndexedFormData(indexedData);
    setShowIndexedData(true);
  }

  const closeIndexedData = () => {
    setActiveIndexedFormData(null);
    setShowIndexedData(false);
  }

  return (
    <div style={{ padding: "8px" }}>
      <Descriptions
        className='descriptions-label'
        title=''
        column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
        layout='vertical'
        colon={false}
      >
        <Descriptions.Item label="Laycan">
          <span className='descriptions'>{laycanDateFormate(laycanData.layCan)}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Quality">
          <span className='descriptions'>{laycanData.quality} {laycanData.qualitySpecifications}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Quantity">
          <span className='descriptions'>{laycanData.quantity} MT</span>
        </Descriptions.Item>
        <Descriptions.Item label="Tolerance">
          <span className='descriptions'>{laycanData.tolerance} %</span>
        </Descriptions.Item>
        <Descriptions.Item label="Mine">
          <span className='descriptions'>{laycanData.mineNameDesc}</span>
        </Descriptions.Item>
      </Descriptions>

      <div style={{fontSize: '16px', fontWeight: 600, marginTop: '16px'}}>Purchase Type(s)</div>
      <div style={{padding: '8px'}}>
        {laycanData.purchaseTypeData?.map((el: any, index: number) => (
          <div key={index} style={{padding: '8px'}} className="contractTypeBlock">
            <Descriptions
              className='descriptions-label'
              title=''
              column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
              layout='vertical'
              colon={false}
            >
              <Descriptions.Item label="Purchase Type">
                <span className='descriptions'>{el.purchaseType}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Load Port">
                <span className='descriptions'>{el.fasAnchorage}</span>
              </Descriptions.Item>
              <Descriptions.Item label="FC Charges">
                <span className='descriptions'>{el.fcCharges}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Index Linked">
                <span className='descriptions'>{(el.isIndexLinked === true || el.isIndexLinked === 'Yes') ? 
                    <a style={{ color: '#0083C9', fontWeight: 500 }} onClick={() => showIndexFormData(el.indexedData)}>Yes</a> : 'No'   
                }</span>
              </Descriptions.Item>
              <Descriptions.Item label="Final Quantity Factor">
                <span className='descriptions'>{el.finalQuantityFactor}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Coal Price (PMT)">
                <span className='descriptions'>{el.coalPricePMT}</span>
              </Descriptions.Item>
              {/* <Descriptions.Item label="VAT">
                <span className='descriptions'>{el.vat}</span>
              </Descriptions.Item> */}
              <Descriptions.Item label="Freight Price (PMT)">
                <span className='descriptions'>{el.freightPricePMT}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Export Documentation">
                <span className='descriptions'>{el.exportDocumentation}</span>
              </Descriptions.Item>
            </Descriptions>
          </div>
        ))}
      </div>

      <Drawer
        width={800}
        open={showIndexedData}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <span style={{ fontSize: '20px', fontWeight: '600', color: '#323F49' }}>Index Linked</span>
            <Button style={{border: 'none', fontSize: '20px', color: '#6B7280', boxShadow: 'none'}} icon={<FontAwesomeIcon icon={faXmark} />} onClick={() => {setShowIndexedData(false)}}></Button>
          </div>
        } 
        closable={false}
        onClose={() => {setShowIndexedData(false)}}
        footer={
            <div style={{ display: 'flex', justifyContent: 'flex-end', color: '#0083C9', fontSize: '14px', fontWeight: 400, margin: '8px'}}>
                <a onClick={() => {setShowIndexedData(false)}}>Close</a>
            </div>
        }
      >
        <CoalIndexView indexedData={activeIndexedFormData} cancelHandler={closeIndexedData} />
      </Drawer>

      <Descriptions
        className='descriptions-label'
        title=''
        column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
        layout='vertical'
        colon={false}
      >
        <Descriptions.Item label="Purchase Term">
          <span className='descriptions'>{laycanData.purchasingTerm}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Spec Standard">
          <span className='descriptions'><a style={{color: '#0083C9', backgroundColor: '#DBF1FD', fontWeight: 500, padding: '4px', borderRadius: '4px', verticalAlign: 'middle'}} onClick={() => setShowSpecsData(true)}>{laycanData.specStandard}</a></span>
        </Descriptions.Item>
        <Descriptions.Item label="Payment Mode">
          <span className='descriptions'>{laycanData.paymentMode}</span>
        </Descriptions.Item>
        {laycanData.paymentMode === PaymentModesEnum.LETTER_OF_CREDIT || laycanData.paymentMode === PaymentModesEnum.BOTH ? (
          <Descriptions.Item label="LC Details">
            <span className='descriptions'>{laycanData.lcValue} {laycanData.lcType === LcTypeEnum.value ? laycanData.lcCurrency : '%'}</span>
          </Descriptions.Item>
        ) : null}

        <Descriptions.Item label="Vessel Type">
          <span className='descriptions'>{laycanData.vesselType}</span>
        </Descriptions.Item>
        {(laycanData.vesselType === VesselTypesEnum.GEARED_AND_GRABBED || laycanData.vesselType === VesselTypesEnum.NA) && (
          <Descriptions.Item label="Loading Rate for Geared">
            <span className='descriptions'>{laycanData.loadingRateForGeared}</span>
          </Descriptions.Item>
        )}
        {(laycanData.vesselType === VesselTypesEnum.GEARLESS || laycanData.vesselType === VesselTypesEnum.NA) && (
          <Descriptions.Item label="Loading Rate for Gearless">
            <span className='descriptions'>{laycanData.loadingRateForGearless}</span>
          </Descriptions.Item>
        )}
        {laycanData.purchaseTypeData?.some(type => type.purchaseType === 'CFR DP' || type.purchaseType === 'CIF DP') && (
          <Descriptions.Item label="Discharge Rate">
            <span className='descriptions'>{laycanData.dischargeRate}</span>
          </Descriptions.Item>
        )}
    
      </Descriptions>

      <Drawer
        open={showSpecsData}
        className='dashborad-popup'
        width={800}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <span style={{ fontSize: '20px', fontWeight: '600', color: '#323F49' }}>Specification Standard</span>
            <Button style={{border: 'none', fontSize: '20px', color: '#6B7280', boxShadow: 'none'}} icon={<FontAwesomeIcon icon={faXmark} />} onClick={() => {setShowSpecsData(false)}}></Button>
          </div>
        } 
        closable={false}
        onClose={() => {setShowSpecsData(false)}}
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end', color: '#0083C9', fontSize: '14px', fontWeight: 400, margin: '8px'}}>
            <a onClick={() => {setShowSpecsData(false)}}>Close</a>
          </div>
        }
      >
        <div>
          <span style={{fontSize: '16px', fontWeight: 600, color: '#64748B'}}>Analysis Type</span>
        </div>
        <span style={{fontSize: '16px', fontWeight: 600, color: '#323F49'}}>{laycanData?.specStandard}</span>
                        
        <div style={{ marginTop: '20px' }}>    
          {laycanData?.specStandard == SpecTypesEnum.BOTH || laycanData?.specStandard == SpecTypesEnum.ASTM ? (
            <SpecsViewPage purchaseTermData={laycanData?.purchaseTermData} specType={SpecTypesEnum.ASTM} contractType={contractType} snapShotPurchaseTermData={laycanData?.purchaseTermData} snapSpecType={SpecTypesEnum.ASTM} /> ) :
            <SpecsViewPage purchaseTermData={laycanData?.purchaseTermData} specType={SpecTypesEnum.ISO} contractType={contractType} snapShotPurchaseTermData={laycanData?.purchaseTermData} snapSpecType={SpecTypesEnum.ISO} />
          }
        </div>
      </Drawer>

      {laycanData.approvalStatus === 'Active' && laycanData.approvedBy &&
        <Descriptions
          className='descriptions-label'
          title=''
          column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
          layout='vertical'
          colon={false}
        >
          <Descriptions.Item label="Approved By">
            <span className='descriptions'>{laycanData.approvedBy}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Approved On">
            <span className='descriptions'>{moment(laycanData.approvedOn).format('DD-MMM-YYYY h:mm a')}</span>
          </Descriptions.Item>
        </Descriptions>
      }
    </div>
  )
}