import { BargeAgreementService, ContractStatusEnum, PlantCodeReqDto, PreviewRequests, VesselAgreementPreviewDto } from "@exportx/shared-models-and-services";
import { Button, Card, Divider, Form, Input, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AlertMessages, isPermissionExist, StatusTag, useAuthState } from "../../../../common";
import moment from 'moment';
import { EditFilled, FileExcelFilled } from '@ant-design/icons';
import { IExcelColumn } from "antd-table-saveas-excel/app";
import { Excel } from "antd-table-saveas-excel";




export const BargeAgreementGrid = () => {
    const { formatMessage: fm } = useIntl();
    const [gridData, setGridData] = useState<any[]>([]);
    const navigate = useNavigate();
    const service = new BargeAgreementService();
    let pathToreDirect = '/bargeAgreement-detailed-view'
    const [searchData, setSearchData] = useState("")
    const { authContext } = useAuthState()
    const { state }: any = useLocation();
    const { baId } = state ? state : { baId: null };

    useEffect(() => {
        if(!isPermissionExist([138])) navigate('/')
        getAllBargeAgreementData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAllBargeAgreementData = () => {
        const req = new PlantCodeReqDto(authContext.defaultPlant);

        service.getAllBargeAgreement(req).then(res => {
            if (res.status) {
                setGridData(res.data);
            } else {
                AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        });
    }

    const sendForApprovalHandler = (id) => {
        service.sendForApproval({ baId: id, plantCode: authContext.defaultPlant }).then(res => {
            if (res.status) {
                getAllBargeAgreementData();
                AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => console.log(err.message));
    }

    const approveOrRejectHandler = (agreementId: string, status: ContractStatusEnum) => {
        const req = new VesselAgreementPreviewDto(agreementId, authContext.defaultPlant, authContext.user.userName, status)
        service.approveOrRejectAgreement(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                getAllBargeAgreementData();
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }


    const tableColumns: any = [
        // {
        //     title: 'S No',
        //     key: 'sno',
        //     width: '70px',
        //     align: 'center',
        //     responsive: ['sm'],
        //     render: (text, object, index) => (page - 1) * 10 + (index + 1)
        //   },
        {
            title: 'Fixture Note',
            dataIndex: 'fixtureNoteNo',
            width: 130,
            align: 'left',
            sorter: (a, b) => a.fixtureNoteNo.localeCompare(b.fixtureNoteNo),
            sortDirections: ['descend', 'ascend'],
            filteredValue: [String(searchData).toLowerCase()],
            onFilter: (value, record) => {
                return (
                    String(record.fixtureNoteNo)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.agreementDate)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.vendorName)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.status)
                        .toLowerCase()
                        .includes(value.toLocaleString()))
            },
            render: (value, record) => {
                const link = `/#/bargeAgreement-detailed-view?ba_Id=${record.baId}`;
                return <>
                    <a href={link} className="link-primary" >
                        {value}
                    </a></>
            }


        },
        {
            title: 'Agreement Date',
            dataIndex: 'agreementDate',
            width: 125,
            align: 'left',
            render: (text, record) => { return record.agreementDate !== undefined ? moment(record.agreementDate).format('YYYY-MM-DD') : "" },
            sorter: (a, b) => moment(a.agreementDate).unix() - moment(b.agreementDate).unix(),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Barge Vendor',
            dataIndex: 'vendorName',
            width: 130,
            align: 'left',
            sorter: (a, b) => a.vendorName.localeCompare(b.vendorName),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: 130,
            align: 'left',
            sorter: (a, b) => a.status.localeCompare(b.status),
            sortDirections: ['descend', 'ascend'],
            render: (text, record) => <>
                <StatusTag status={record.status} />
            </>,
        },
        {
            title: 'Action',
            className: 'operations',
            width: 130,
            align: 'Center',

            render: (text, rowData) => (
                <span>{isPermissionExist([139]) && (
                    <EditFilled
                        onClick={() => {
                            editClickHandler(rowData.baId);
                        }}
                        style={{ color: '#1890ff', fontSize: '16px', display: `${rowData.status !== ContractStatusEnum.ACTIVE ? "" : 'none'}` }}
                    />)}
                    <Divider type="vertical"></Divider>&nbsp;&nbsp;
                    {rowData.status === ContractStatusEnum.DRAFT  && <Button type="primary" size="small" onClick={() => sendForApprovalHandler(rowData.baId)}>Send For Approval</Button>}
                    {(rowData.status === ContractStatusEnum.PENDING_APPROVAL && isPermissionExist([142])) && <Button type="primary" size="small" onClick={() => approveOrRejectHandler(rowData.baId, ContractStatusEnum.ACTIVE)}>Approve</Button>} &nbsp;&nbsp;
                    {(rowData.status === ContractStatusEnum.PENDING_APPROVAL && isPermissionExist([143])) && <Button type="dashed" size="small" onClick={() => approveOrRejectHandler(rowData.baId, ContractStatusEnum.REJECT)}>Reject</Button>}
                </span>)
        }
    ];
    const redirectToFullView = (baId: string) => {
        navigate(pathToreDirect, { state: { baId } });
    }
    const editClickHandler = (baId: string) => {
        const req = { ...new PreviewRequests(baId, authContext.defaultPlant) };
        service.getBargeAgreementPreviewData(req).then(res => {
            if (res.status) {
                navigate('/bargeAgreement-update', { state: { record: res.data, isUpdate: true } });
            } else {
                AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }

        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }

    const handleExport = (e: any) => {
        e.preventDefault();

        // Formatting columns - to set default render

        const currentDate = new Date()
            .toISOString()
            .slice(0, 10)
            .split("-")
            .join("/");
        // Formatting columns - to set default render
        let cloneArr = tableColumns.slice(0);
        cloneArr.splice(0, 1);
        cloneArr.splice(-1);
        const exportingColumns: IExcelColumn[] = cloneArr.map((item) => {
            if (typeof item.title === 'string')
                return { title: item.title, dataIndex: item.dataIndex };
            else
                return { title: item.dataIndex.toLocaleUpperCase(), dataIndex: item.dataIndex };
        });

        const excel = new Excel();
        excel
            .addSheet("Sheet1")
        excel.addRow();
        excel.addColumns(exportingColumns)
            .addDataSource(gridData)
            .saveAs(`BargeAgreement-${currentDate}.xlsx`);
    };

    return (
        <Card
            title={fm({ id: `mdm.components.header`, defaultMessage: `Barge Agreement` })}
            extra={<>{isPermissionExist([137]) && (<Button
                type="primary"
                onClick={() => navigate('/bargeAgreement-creation')}
                className='panel_button'
            >Create</Button>
            )}</>}
        >   
            {isPermissionExist([144]) && (<Tooltip placement="topRight" title="Export As Excel" color="green">
                <Button
                    //disabled={gridData.length === 0}
                    size='small'
                    type="default"
                    style={{ float: "right" }}
                    //className={gridData.length === 0 ? "export-excel-btn-disabled" : "export-excel-btn"}
                    onClick={handleExport}
                    icon={<FileExcelFilled />}
                >Export</Button>
            </Tooltip>)}
            <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} style={{ width: 200, float: "right" }} />

            <Table
                className='contracts'
                rowKey={record => record.baId}
                columns={tableColumns}
                dataSource={gridData}
                pagination={false}

                scroll={{ x: 500 }}
                size="large"
                bordered
            ></Table>
        </Card>
    )
}


